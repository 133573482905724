import React, { Component } from 'react'

export default class About extends Component {
  render() {
    return (
      <div className="container" id="about">
        <h1>Club information</h1>
        <div className="col-main">
          <h2>Club cancelled...</h2>
          <p>Due to the current situation with guidelines around limiting gatherings the club sessions are cancelled for the moment. We will review each session nearer the time.</p>
          <h2>About the club</h2>
          <p itemScope itemType="http://data-vocabulary.org/Organization">
            <span itemProp="name">Hastings Community Circus</span>
            <span className="details">has been going for the past 20 years and is usually on the first Friday of every month. Now located at</span>
            <span itemProp="address" itemScope itemType="http://data-vocabulary.org/Address">
              <span className="details">Ore Community Centre</span>,
              <span className="details" itemProp="street-address">455 Old London Rd</span>,
              <span className="details" itemProp="locality">Hastings</span>,
              <span className="details" itemProp="postal-code">TN35 5BH</span>.
          </span>
          </p>
          <p>The club ranges from very experienced jugglers who use their skills as a profession
          to people who learn these skills as a hobby.</p>
          <h2>Club activities</h2>
          <ul className="item-list">
            <li>Juggling</li>
            <li>Unicycling</li>
            <li>Diablo</li>
            <li>Devilstick</li>
            <li>Plate spinning</li>
            <li>Walking globe</li>
          </ul>
        </div>
        <div className="col-aside">
          <div itemScope itemType="http://data-vocabulary.org/Event">
              <div className="module">
                <h2 itemProp="summary">Next club</h2>
                <p>
                  <time itemProp="startdate" dateTime="">
                      <span className="value value-large">
                        To be decided
                      </span>
                      <span className="value value-medium"></span>
                  </time>
                </p>
              </div>
              <div className="module" itemProp="tickets" itemScope itemType="http://data-vocabulary.org/Offer">
                <h2>Info</h2>
                <p className="value-medium">Price per Person -
                  <span itemProp="highPrice">&pound;4</span>
                  <span itemProp="currency" content="GBP"></span>
                  </p>
                <span className="note">Children under 11 must be accompanied by an adult.</span>
              </div>
          </div>
          <div className="module" itemScope itemType="http://data-vocabulary.org/Organization">
            <h2>Links</h2>
            <ul className="item-list">
              <li>
                <a href="http://www.jugglingedge.com/club.php?ClubID=14" itemProp="url">
                  Find us on The Juggling Edge
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/hastingscommunitycircus" itemProp="url">
                  Visit our Facebook page
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}
