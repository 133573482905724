import React, { Component } from 'react';
import './App.css';
import About from './components/about';
import Photos from './components/photos';
import Contact from './components/contact';

class App extends Component {
  render() {
    return (
      <div id="main">
        <About></About>
        <Photos></Photos>
        <Contact></Contact>
      </div>
    );
  }
}

export default App;
