import React, { Component } from 'react'

export default class Contact extends Component {
  render() {
    return (
      <div className="container" id="contact">
        <h1>Contact us</h1>
        <div className="col-half">
          <h2>Send us an email</h2>
          <p>For any questions about the club or enquiries:</p>
          <p><a href="mailto:hastingscommunitycircus-contact@yahoo.com">Send us an email</a></p>
        </div>
        <div className="col-half last">
          <h2>Map</h2>
            <iframe title="map of club" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10070.750396380949!2d0.610047!3d50.873983!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x42a6d26c4f77ec72!2sOre%20Community%20Centre!5e0!3m2!1sen!2suk!4v1572298662655!5m2!1sen!2suk" width="464" height="450" frameBorder="0" style={{border: 0}} allowFullScreen=""></iframe>
        </div>
      </div>
    )
  }
}
