import React, { Component } from 'react'

export default class Photos extends Component {
  render() {
    return (
      <div className="container" id="photos">
        <h1>Club photos</h1>
        <div className="col-half">
          <iframe title="club members juggling" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fhastingscommunitycircus%2Fphotos%2Fa.590266227825518%2F959670394218431%2F&width=400&show_text=false&height=479&appId" width="400" height="404" style={{border: "none", overflow: "hidden"}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
        </div>
        <div className="col-half last">
          <iframe title="juggling in the park" src="https://www.facebook.com/plugins/video.php?height=420&href=https%3A%2F%2Fwww.facebook.com%2Fhastingscommunitycircus%2Fvideos%2F496873694216298%2F&show_text=false&width=400" width="400" height="300" style={{border: "none", overflow: "hidden"}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
        </div>
        <div className="col-main">
          <p>
            <a href="https://www.facebook.com/hastingscommunitycircus/photos/">View more photos and videos on Facebook</a>
          </p>
        </div>
      </div>
    )
  }
}
